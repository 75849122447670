import React from "react"
import PropTypes from "prop-types"
import { ChakraProvider } from "@chakra-ui/react"

import { theme } from "~config/theme"

const WrapRootElement = ({ element }) => {
    return <ChakraProvider theme={theme}>{element}</ChakraProvider>
}

WrapRootElement.propTypes = {
    element: PropTypes.element,
}

export default WrapRootElement
