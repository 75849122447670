exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cbb-tsx": () => import("./../../../src/pages/cbb.tsx" /* webpackChunkName: "component---src-pages-cbb-tsx" */),
  "component---src-pages-cfb-tsx": () => import("./../../../src/pages/cfb.tsx" /* webpackChunkName: "component---src-pages-cfb-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nfl-tsx": () => import("./../../../src/pages/nfl.tsx" /* webpackChunkName: "component---src-pages-nfl-tsx" */),
  "component---src-pages-whitepaper-tsx": () => import("./../../../src/pages/whitepaper.tsx" /* webpackChunkName: "component---src-pages-whitepaper-tsx" */)
}

